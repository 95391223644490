import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import bannerImg from '../../images/banner4.svg'
import './shoppingTaxi.css'
import { motion } from 'framer-motion';

const ShoppingTaxi = () => {
  const points = [
    {
      text: "Affordable and budget-friendly rates."
    },
    {
      text: "Professional, courteous drivers."
    },
    {
      text: "Hygienic and well-maintained vehicles."
    },
    {
      text: "Thoroughly cleaned and sanitized taxis."
    },
    {
      text: "Fleet includes Sedans, SUVs, and Tempo taxis."
    },
    {
      text: "Free luggage handling services."
    },
  ];
  
  const faq = [
    {
      title: "Is the Shopping Taxi service available 24/7?",
      desc: "Yes, White Cabs offers 24/7 shopping taxi services in Canada. Whether for shopping, business meetings, hotels, or airport transfers, our taxis are available at flat rates for any time of day or night. Call us at780 670 0082 or visit whitecabs.ca to book a sedan, SUV, or Tempo cab."
    },
    {
      title: "Can I hire a sanitized taxi during a lockdown?",
      desc: "Yes, during lockdowns, White Cabs provides fully sanitized taxis for all essential transportation needs. Simply provide us with the necessary information by form or phone, and we’ll ensure your ride is safe and sanitary."
    },
  ];
  
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="shopping container"
    >
      <Banner 
        comp="home" 
        title="Affordable Shopping Taxi Services" 
        description="Looking for a reliable ride to shop in your city? White Cabs provides low-rate shopping taxi services across Canada. Book a ride from your home to malls, grocery stores, or airports for personal, business, or tourist purposes. Choose from a variety of options, including sedans, SUVs, Tempo taxis, and wheelchair-accessible vans. All vehicles are thoroughly sanitized for your safety." 
        bannerImg={bannerImg} 
      />
      <Benefits title="Why Choose White Cabs?" points={points} />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  );
};


export default ShoppingTaxi