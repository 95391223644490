import React from 'react'
import Banner from '../../components/Banner/Banner'
import Faq from '../../components/Faq/Faq'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/businessTaxi.jpg'
import { motion } from 'framer-motion';

const businessService = [
  {
    title: "Book Business Taxi Service with Us",
    desc: "If you're a business professional looking to book a taxi, give us a call and we will arrange a convenient ride to your office. For frequent users, we offer tailored packages, with discounts available for regular customers. Our executive team will assist you with all your business transportation needs. Quality service is guaranteed with our Business Taxi Service. Monthly and quarterly packages are also available."
  },
  {
    title: "Business Taxi Service from the Airport",
    desc: "Arriving from abroad? Our Business Taxi Service provides airport pick-up and transfers to your hotel or business destination. We ensure timely arrivals, so you can get to your meeting or event without any hassle. Book a taxi online now for a smooth and efficient ride."
  },
  {
    title: "Business Areas",
    desc: "Canada's expansive business landscape requires efficient and comfortable transportation. Business professionals across the country rely on our Business Taxi Services for seamless travel. Our taxis are designed for comfort, ensuring a luxury experience for long-distance trips to business areas, making sure you arrive refreshed and on time."
  },
  {
    title: "Safety and Cleanliness",
    desc: "The safety and cleanliness of our Business Taxi Service are our top priority. In compliance with COVID-19 safety measures, our taxis are regularly disinfected and cleaned. Drivers and staff wear gloves and masks, and maintain social distancing practices before, during, and after every ride. Additionally, we check the temperature of both the rider and passengers to ensure a safe experience."
  },
];

const faq = [
  {
    title: "Looking for a Business Cab?",
    desc: "White Cabs provides business-class vehicles tailored to your needs, offering a premium experience."
  },
  {
    title: "Need a Business Cab Service from the Airport?",
    desc: "You're in the right place! We provide prompt and professional business cab service for airport pick-ups."
  },
];

const BusinessTaxi = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="businesstaxi container"
    >
      <Banner 
        comp="home" 
        title="Professional Business Taxi Services" 
        description="We offer a fleet of luxury business taxis to cater to your needs, including sedans, SUVs, and executive cars. Our service includes personal business cabs, chauffeured town cars, and corporate vehicles. Whether you need a ride for shopping, office use, airport transfers, or any other business-related purpose, we are available 24/7. Book online or call us today."
        bannerImg={bannerImg} 
      />
      <Services data={businessService} />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  );
};


export default BusinessTaxi