import React from 'react'
import Banner from '../../components/Banner/Banner'
import './about.css'
import { motion } from 'framer-motion';
import bannerImg2 from '../../images/about-us.jpg'

const About = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} className="container about_us" id="about">
        <h2>About Us</h2>
        <Banner comp="home" title="The mission of white Cabs" description="White Cabs provides exceptional customer service and cost-effective solutions with clean, well-maintained, and smoke-free vehicles. We serve WhiteCourt in Alberta, Canada." bannerImg={bannerImg2} />

        <h2>Feature of White Cabs</h2>
        <div className="timeline">
            <div className="cont left">
                <div className="text_box">
                    <p>It will significantly improve response times and passengers comfort by providing a faster, more accurate and silent dispatch service. </p>
                    <span className="left_arrow"></span>
                </div>
            </div>
            <div className="cont right">
                <div className="text_box">
                    <p>Professional services can be delivered more consistently with a drivers information system. </p>
                    <span className="right_arrow"></span>
                </div>
            </div>
            <div className="cont left">
                <div className="text_box">
                    <p>Information service that provide information, such as map, routes, and other useful services to passengers. </p>
                    <span className="left_arrow"></span>
                </div>
            </div>
            <div className="cont right">
                <div className="text_box">
                    <p>Credit and direct debit cards can be processed more securely and conveniently. </p>
                    <span className="right_arrow"></span>
                </div>
            </div>
        </div>
    </motion.div>
  )
}

export default About