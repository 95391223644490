import React from 'react'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car1.svg'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner';

const ParklandTaxi = () => {
  const parklandService = [
    {
      title: "Variety of Options with Parkland Taxi Service",
      desc: "Choose from a range of modern, comfortable, and luxurious cabs with Parkland Taxi Service. Our reliable taxis are equipped with spacious interiors to ensure a relaxing ride, whether you're sightseeing or commuting."
    },
    {
      title: "Special Offers and Discounts",
      desc: "Take advantage of flat rates and exclusive offers with Parkland Taxi Service. We provide affordable rides from Parkland, Alberta to destinations in Edmonton and beyond. Check out our various offers to enjoy great rates on your next trip."
    },
    {
      title: "Professional and Experienced Chauffeurs",
      desc: "Our chauffeurs are well-trained professionals who prioritize your comfort and safety. Dressed in proper uniforms and equipped with mobile phones, they are familiar with the routes and always ready to assist with luggage, providing exceptional service."
    },
    {
      title: "24/7 Taxi Service in Parkland",
      desc: "White Cabs offers 24/7 taxi services in Parkland at affordable rates. Book a ride anytime, from any location, and enjoy a stress-free trip with our punctual drivers. Contact us at +1780 670 0082 to book your ride and explore various service packages."
    },
    {
      title: "Comfortable and Affordable Journey",
      desc: "White Cabs provides 24/7 Parkland Taxi Service with affordable, fixed-rate options. Choose one-way or round-trip rides, pay by credit or debit card, and enjoy a comfortable journey to your destination. Explore local hotels and restaurants along the way."
    },
    {
      title: "Budget-Friendly Options Available",
      desc: "Looking for an economical ride? White Cabs offers budget-friendly rates with fixed pricing. Book a 24/7 cab service in Parkland for both short and long trips, and enjoy local dining and hotel stops as part of your travel experience."
    },
  ];
  
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="parklandtaxi container"
    >
      <Banner 
        comp="home" 
        title="Parkland Taxi Service" 
        description="For reliable and luxurious Parkland Taxi Service, book with White Cabs. Enjoy 24/7 transportation services with on-demand delivery options and timely pick-up and drop-off at nominal fares. Whether for hotel commutes or city travels, White Cabs ensures a comfortable journey."
        bannerImg={bannerImg} 
      />
      <div className="applink">
        <DownloadBanner />
      </div>
      <Services title="Our Services" data={parklandService} />
    </motion.div>
  );
};


export default ParklandTaxi