import React from 'react'
import './contact.css'

const ContactUs = () => {
  return (
    <div className="container contact" id="contact">
        <h2>Get in Touch</h2>
        <div className="contact_map">
        <div className="download3"></div>
            </div>
        <div className="contact_content">
        <div className="contact_container">
       
            <div className="left-side">
                <div className="address details">
                    <i className="fas fa-map-marker-alt"></i>
                    <div className="topic">Address</div>
                    <div className="text-one">4907 52 Ave #8, Whitecourt, AB T7S 1N9, Canadá</div>
                </div>
                <div className="phone details">
                    <i className="fa fa-phone"></i>
                    <div className="topic">Phone</div>
                    <div className="text-one">+1 780 670 0082</div>
                </div>
                <div className="email details">
                    <i className="fas fa-envelope"></i>
                    <div className="topic">Email</div>
                    <div className="text-one">manager@whitecabs.ca</div>
                </div>
            </div>
            <div className="middle-side">
                <div className="topic-text">Send Us a message</div>
                {/* <!-- <p>send ur message</p> --> */}
                <form id="contact-form">
                    <div className="input-box">
                        <input type="text" id="name" placeholder="Enter your name" required />
                    </div>
                    <div className="input-box">
                        <input type="email" id="email" placeholder="Enter your email" required />
                    </div>
                    <div className="input-box message-box">
                        <textarea placeholder="Enter your name" id="message" required></textarea>
                    </div>
                    <div className="button">
                        <input type="submit" value="Send Now" />
                    </div>
                </form>
            </div>
        </div>
        <div className="download">
            {/* <img src={download} alt="" /> */}
        </div>
        </div>
    </div>
  )
}

export default ContactUs