import React from 'react'
import './hotelTaxi.css'
import bannerImg from '../../images/hotelTaxi.jpg'
import Benefits from '../../components/Benefits/Benefits'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import { motion } from 'framer-motion';

const points = [
  {
    text: "Affordable and competitive hotel taxi rates."
  },
  {
    text: "Punctual and professional drivers."
  },
  {
    text: "Clean and well-maintained taxis."
  },
  {
    text: "Fully sanitized vehicles for your safety."
  },
  {
    text: "Wide variety of taxis including Sedans, SUVs, and Tempo options."
  },
  {
    text: "Free luggage assistance with every ride."
  },
];

const bookingService = [
  {
    title: "Hotel Taxi Service with White Cabs",
    desc: "Call us at780 670 0082 to book a reliable taxi from the airport to your hotel anywhere in Canada. White Cabs provides efficient hotel taxis for tourists and business travelers alike. Our courteous drivers can make stops or take shortcuts to get you to your destination comfortably and on time. Book now and enjoy a stress-free journey!"
  },
  {
    title: "Easy Hotel Transfers from the Airport",
    desc: "For convenient and affordable transport to hotels across Canada, White Cabs is here to assist. Call780 670 0082 for easy taxi booking and travel comfortably to your preferred hotel. Our professional drivers handle your luggage with care, providing dependable service to Sherwood Park, Parkland, and beyond. Explore Canada worry-free with White Cabs."
  },
];

const HotelTaxi = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="home container"
    >
      <Banner 
        comp="home" 
        title="Reliable Hotel Taxi Services" 
        description="Planning a trip to Canada? Canada’s top cab service is just a phone call away at780 670 0082. We offer reliable lodging transfers and taxi services for tourists and business visitors. Enjoy affordable taxi rides for shopping, office commutes, and sightseeing with our sanitized fleet, including Sedans, SUVs, and Tempos, for your safety."
        bannerImg={bannerImg} 
      />
      <Services title="Our Services" data={bookingService} />
      <Benefits title="Why Choose White Cabs" points={points} />
    </motion.div>
  );
};


export default HotelTaxi