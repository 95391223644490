import React from 'react'
import bannerImg1 from '../../images/limousine.jpg'
import bannerImg2 from '../../images/hotelTaxi.jpg'
import bannerImg3 from '../../images/banner5.jpg'
import bannerImg4 from '../../images/banner4.svg'
import bannerImg5 from '../../images/wheelchairTaxi.jpg'
import bannerImg6 from '../../images/airport.jpg'
import bannerImg7 from '../../images/businessTaxi.jpg'
import bannerImg8 from '../../images/banner8.svg'
import bannerImg9 from '../../images/banner9.svg'
import bannerImg10 from '../../images/banner10.jpg'
import ServiceCard from '../../components/ServiceCard/ServiceCard'
const cards = [
    {
        img: bannerImg1,
        title: 'Luxurious White Limousine Services',
        desp: 'Looking for a safe, professional white limousine service in WhiteCourt Taxi, AB? We offer on-demand transportation with luxurious, eco-friendly vehicles. Our fleet includes options perfect for special events, holidays, or everyday travel with complimentary WiFi. Choose White Cabs for your flat-rate needs in Whitecourt, whether for local rides or journeys to Edmonton Airport.',
        link: '/service/white-limousine-service'
    },
    {
        img: bannerImg2,
        title: 'Reliable Hotel Taxi Services',
        desp: "Visiting Canada? White Cabs in Whitecourt offers top-notch hotel taxi services. Call780 670 0082 for reliable, affordable rides to and from the hotel, airport, or shopping areas. Our sanitized, flat-rate cabs include sedans, SUVs, and tempos, available for office, personal, or tourist use, with options to Fox Creek and Edmonton Airport.",
        link: '/service/hotel-taxi'
    },
    {
        img: bannerImg3,
        title: 'Convenient On-Demand Delivery',
        desp: 'Get on-demand delivery taxis in Canada with White Cabs, providing a hassle-free way to transport items across the city and beyond. Call us for courier services or airport taxi pickups. White Cabs offers flexible delivery solutions for food, shopping, and more, covering Whitecourt, Fox Creek, and airport transfers to Edmonton.',
        link: '/service/shopping-taxi-service'
    },
    {
        img: bannerImg4,
        title: 'Affordable Shopping Taxi Services',
        desp: 'Need a flat-rate taxi for shopping in Whitecourt or Fox Creek? Call White Cabs at780 670 0082 for affordable shopping taxi services across Canada. We offer convenient rides from your home to malls, airports, or hotels. Choose from sedans, SUVs, tempos, and wheelchair-accessible vans – all sanitized and ready for personal or business use.',
        link: '/service/shopping-taxi-service'
    },
    {
        img: bannerImg5,
        title: 'Accessible Wheelchair Taxi Services',
        desp: "White Cabs offers accessible transportation for passengers with mobility aids in Whitecourt and nearby areas. Our specialized wheelchair taxi service ensures a smooth, safe ride, available seven days a week. We provide door-to-door medical transportation, including flat-rate service to hospitals, hotels, and Edmonton Airport.",
        link: '/service/wheelchair-van'
    },
    {
        img: bannerImg6,
        title: 'Reliable Airport Taxi Services',
        desp: 'Looking for a reliable airport taxi in Whitecourt to Edmonton Airport? White Cabs offers flat-rate taxi services for single passengers and groups, with options like sedans, SUVs, and tempos. Our services are available around the clock for personal or corporate needs, covering Whitecourt and Fox Creek.',
        link: '/service/airport-taxi'
    },
    {
        img: bannerImg7,
        title: 'Professional Business Taxi Services',
        desp: 'White Cabs provides a luxury fleet for business transportation, with sedans, SUVs, and executive vehicles. Our services include flat-rate options for Whitecourt and Fox Creek, perfect for corporate accounts, office pickups, and airport transfers. Book online or call us 24/7 for a smooth, convenient experience.',
        link: '/service/business-taxi'
    },
    {
        img: bannerImg8,
        title: '24/7 Reliable Taxi Services',
        desp: "White Cabs offers 24/7 flat-rate taxis in Whitecourt, covering destinations like Fox Creek and Edmonton Airport. Our professional drivers ensure safe and comfortable travel any time of day or night. Visit whitecabs.ca to learn more or call780 670 0082 for immediate bookings.",
        link: '/service/24-7-taxi'
    },
    {
        img: bannerImg9,
        title: 'Flat-Rate Taxi Services',
        desp: 'White Cabs now offers flat-rate taxis in Whitecourt, ideal for shopping, office commutes, personal use, or airport pickups. Our sanitized fleet includes sedans, SUVs, and tempos, available for travel to local destinations or longer routes, like Edmonton Airport or Fox Creek.',
        link: '/service/flat-taxi'
    },
    {
        img: bannerImg10,
        title: 'Emergency Car Boosting Services',
        desp: 'Stranded with a dead car battery in Whitecourt? White Cabs provides reliable car boosting services 24/7. Whether you’re at home, work, or on the road, our professional team is equipped to jumpstart your vehicle quickly and safely. Call780 670 0082 for immediate assistance.',
        link: '/service/car-boosting-service'
    },
];

const Service = () => {
  return (
    <div className='blog container'>
        <h2 className='title'>Explore Our Services        </h2>
        <div className="blog__container">
        {
           cards.map((elm,i)=>(
            <ServiceCard key={i} elm={elm} />
           )) 
        }
        </div>
    </div>
  )
}
export default Service