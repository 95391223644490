import React from 'react'
import Banner from '../../components/Banner/Banner'
import './whiteLimousine.css'
import bannerImg from '../../images/limousine.jpg'
import Benefits from '../../components/Benefits/Benefits'
import { motion } from 'framer-motion';

const points = [
  {
    text: "White limousine service offers a stylish, flat-rate transportation solution for trips within Whitecourt, as well as rides to Edmonton Airport."
  },
  {
    text: "It eliminates the hassle and downtime of waiting for public transportation in Whitecourt and surrounding areas."
  },
  {
    text: "Our limousine service is ideal for long-distance trips, international airport transportation, or luxury rides to Fox Creek and other destinations."
  },
  {
    text: "Experienced and professional drivers ensure a safe, attentive experience throughout your journey."
  },
  {
    text: "White limo service is available 24/7 and can be booked for any event, from weddings to business trips, including airport transfers from Whitecourt to Edmonton."
  },
  {
    text: "White Cabs also offers a variety of other flat-rate cab services in Whitecourt, such as shopping and airport transportation."
  },
  {
    text: "A White limousine is a popular choice for weddings in Edmonton, providing elegance and convenience for the occasion."
  },
  {
    text: "Limousines are popular among dignitaries, corporate clients, and individuals looking for a luxurious travel experience."
  },
  {
    text: "The classic stretch limo is our most requested vehicle, comfortably seating up to 8 passengers with luggage."
  },
];

const WhiteLimousine = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="home container"
    >
      <Banner 
        comp="home" 
        title="Luxurious White Limousine Services" 
        description="Looking for a reliable, flat-rate white limousine service in Whitecourt or a ride to Edmonton Airport? Look no further! White Cabs provides on-demand, chauffeur-driven vehicles of your choice, featuring eco-friendly, electric-powered options with free WiFi, perfect for special events or holidays." 
        bannerImg={bannerImg} 
      />
      <Benefits 
        title="Benefits of White Limousine Service" 
        description="White limousine services provide luxury, comfort, and convenience for a variety of occasions. Perfect for those looking to travel in style, our services offer a range of high-end features suitable for everything from weddings to business trips." 
        points={points}  
      />
    </motion.div>
  );
};


export default WhiteLimousine