import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/airport.jpg'
import { motion } from 'framer-motion';

const points = [
  {
    text: "White Cabs provides a stress-free and memorable travel experience for all passengers."
  },
  {
    text: "Our high-quality services include airport taxi transfers to popular destinations such as WhiteCourt Taxi."
  },
  {
    text: "White Cabs prioritizes punctuality, ensuring passengers arrive at their destinations comfortably and on time."
  },
  {
    text: "Customers can conveniently reserve a taxi for their desired destination through our reliable airport cab service."
  },
  {
    text: "Our drivers are skilled professionals committed to providing top-notch service to our clients."
  },
  {
    text: "White Cabs' airport taxi services are always available outside major railway stations, ensuring quick and early arrivals."
  }
];

const demandService = [
  {
    title: "Sanitized Airport Taxi Service",
    desc: "White Cabs prioritizes passenger safety by offering sanitized airport taxi services across Canada. In light of the ongoing pandemic, we ensure that every ride is secure, reliable, and stress-free, all at reasonable fares."
  },
  {
    title: "24/7 Airport Taxi Service with Luxury Fleet and On-Demand Delivery",
    desc: "Our airport taxi service operates 24/7 with punctual, professional drivers who arrive ahead of time. We offer a large fleet of vehicles, including luxury taxis for tourists, and also provide on-demand delivery services. Corporate and personal accounts are available for frequent travelers, and we cater to passengers arriving at metro airports."
  },
  {
    title: "Safe and Secure Taxi Service",
    desc: "Safety is our top priority. White Cabs offers disinfected and clean cabs, with drivers wearing masks and gloves. Each cab is sanitized before and after every ride. We also maintain social distancing and regularly check the temperature of both passengers and drivers to ensure everyone's safety. Our airport taxi services are available 24/7 to provide safe, secure transportation, even during late hours."
  }
];

const AirportTaxi = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="airportTaxi container"
    >
      <Banner 
        comp="home" 
        title="Reliable Airport Taxi Services" 
        description="Need a reliable airport taxi service in Canada? Call780 670 0082 to book with White Cabs. Our flat-rate taxi service is available for single passengers or groups of any size. Choose from sedans, SUVs, and Tempo vehicles. We also offer taxi services for hotels, offices, personal use, restaurants, and more. Book your airport taxi to any destination, anytime." 
        bannerImg={bannerImg} 
      />
      <Benefits title="Benefits of Booking Airport Taxi Service" points={points} />
      <Services title="Our Services" data={demandService} />
    </motion.div>
  );
};


export default AirportTaxi